import React from "react";
import { Col, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Carousel from "react-bootstrap/Carousel";
import { BASE_URL, URL } from "../../url/url";
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ButtonLoader from "./ButtonLoader";

const SignupModal = ({
  signupPopupShow,
  setsignupPopupShow,
  onChangeMobile,
  handleRegister,
  handleLoginPopup,
  btnLoading,
}) => {
  const health_Related_Queries = `${BASE_URL}/2023/05/login-signup/Health-Related-Queries.png`;
  const your_Go_To_Health_App = `${BASE_URL}/2023/05/login-signup/Your-Go-To-Health-App.png`;
  const know_Your_Medicines = `${BASE_URL}/2023/05/login-signup/Know-Your-Medicines.png`;
  const home_Delivery_of_Medicines = `${BASE_URL}/2023/05/login-signup/Home-Delivery-of-Medicines.png`;

  return (
    <Modal
      size="xl"
      className="loginModelBody"
      show={signupPopupShow}
      onHide={() => setsignupPopupShow(false)}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Row>
          <Col sm={6}>
            <Carousel className="loginformSlider">
              <Carousel.Item>
                <LazyLoadImage
                  src={health_Related_Queries}
                  className="img-fluid"
                  alt=""
                />
                <Carousel.Caption>
                  <h3>Health Related Queries?</h3>
                  <p>
                    Consult our certified doctors from anywhere, anytime, and
                    for free. We guarantee your privacy.
                  </p>
                </Carousel.Caption>
              </Carousel.Item>

              <Carousel.Item>
                <LazyLoadImage
                  src={your_Go_To_Health_App}
                  className="img-fluid"
                  alt=""
                />
                <Carousel.Caption>
                  <h3>Make Healthcare Simpler</h3>
                  <p>
                    Get medicine information, order medicines, book lab tests
                    and consult doctors online from the comfort of your home.
                  </p>
                </Carousel.Caption>
              </Carousel.Item>

              <Carousel.Item>
                <LazyLoadImage
                  src={know_Your_Medicines}
                  className="img-fluid"
                  alt=""
                />
                <Carousel.Caption>
                  <h3>Know Your Medicines</h3>
                  <p>
                    View medicine information like usage, side effects and
                    cheaper substitutes before you take them.
                  </p>
                </Carousel.Caption>
              </Carousel.Item>

              <Carousel.Item>
                <LazyLoadImage
                  src={home_Delivery_of_Medicines}
                  className="img-fluid"
                  alt=""
                />
                <Carousel.Caption>
                  <h3>Medicines, Home Delivered</h3>
                  <p>
                    Order any medicine or health product and we’ll deliver it
                    for free. Enjoy discounts on everything.
                  </p>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <LazyLoadImage
                  src={health_Related_Queries}
                  className="img-fluid"
                  alt=""
                />
                <Carousel.Caption>
                  <h3>Health Related Queries?</h3>
                  <p>
                    Consult our certified doctors from anywhere, anytime, and
                    for free. We guarantee your privacy.
                  </p>
                </Carousel.Caption>
              </Carousel.Item>
            </Carousel>
          </Col>
          <Col sm={6}>
            <div className="loginform">
              <Form validated={true} onSubmit={handleRegister}>
                <h1>Sign Up </h1>
                <p>
                  Please enter your Mobile number to receive One Time Password
                  (OTP)
                </p>
                <Form.Group className="mb-10" controlId="formBasicMobile">
                  <Form.Control
                    type="text"
                    required
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    minLength={10}
                    maxLength={10}
                    variant="outline"
                    placeholder="Enter Mobile Number"
                    onChange={onChangeMobile}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-8 mt-20"
                  controlId="formBasicCheckbox"
                >
                  <Form.Check
                    type="checkbox"
                    style={{ display: "none" }}
                    label="Are you a healthcare professional?"
                  />
                </Form.Group>
                {/* <div className="mt-2">
                <button disabled={isSubmitting} className="btn btn-danger">
                  {isSubmitting && (
                    <span className="spinner-grow spinner-grow-sm"></span>
                  )}
                  Submit
                </button>
             </div> */}
                <button
                  variant="primary"
                  type="submit"
                  className="CommonButtonStyle"
                  disabled={btnLoading}
                >
                  {btnLoading && <ButtonLoader />}
                  Continue
                </button>
                <p className="mt-10">
                  Have an account?{" "}
                  <Nav.Link className="LoginLink" onClick={handleLoginPopup}>
                    Login
                  </Nav.Link>
                </p>
                <p className="mt-20">
                  By signing up, you agree to our{" "}
                  <Link
                    className="LoginLink"
                    onClick={() => setsignupPopupShow(false)}
                    to="/pages/terms-and-conditions"
                  >
                    Terms and Conditions
                  </Link>
                  <span> & </span>
                  <Link
                    className="LoginLink"
                    to="/pages/privacy-policy"
                    onClick={() => setsignupPopupShow(false)}
                  >
                    Privacy Policy
                  </Link>
                </p>

                <p className="mt-20">
                  <Nav.Link className="LoginHelp" href="/pages/contact-us">
                    Need Help? Get In Touch
                  </Nav.Link>
                </p>
              </Form>
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default SignupModal;
