import React from "react";
import {
  BrowserRouter as Router,
} from "react-router-dom";
import ScrollToTop from "./components/Common/ScrollToTop";
import { CartProvider } from "./components/Context/CartContext";
import RouterURL from "./router/routerURL";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <CartProvider>
        <RouterURL />
      </CartProvider>
    </Router>
  );
}

export default App;
