import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import * as Icon from "react-bootstrap-icons";
import { BASE_URL, URL } from "../../url/url";
import style from "../../Assets/layout.module.css";
import { Link } from "react-router-dom";
import whatsAppIcon from "../../image/whatsapp.png";
import uparrowIcon from "../../image/scrolltotop.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function Footer({ AllCategoryData }) {
  const [isVisible, setIsVisible] = useState(false);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    const scrollThreshold = 300;

    setIsVisible(scrollTop > scrollThreshold);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const current = new Date();
  const date = `${current.getFullYear()}`;
  const logo = `${BASE_URL}/2023/05/mchemist-logo.jpg`;
  const handleRedirectWhatsApp = () => {
    window.open(
      `https://api.whatsapp.com/send/?phone=919625996302&text&type=phone_number&app_absent=0`
    );
  };
  return (
    <footer className={style.footerMain}>
      <Container>
        <Row className="g-3">
          <Col sm={12} md={3}>
            <div className={style.footerItemsHead}>
              <h3>CAN WE HELP YOU?</h3>
            </div>
            <ul className={style.footerItemsMain}>
              <li className={style.footerItems}>
                <Link to={"/pages/contact-us"}>Contact Us</Link>
              </li>
              <li className={style.footerItems}>
                <Link to={"/pages/terms-and-conditions"}>
                  Terms and Conditions
                </Link>
              </li>
              <li className={style.footerItems}>
                <Link to={"/pages/privacy-policy"}>Privacy Policy</Link>
              </li>
              <li className={style.footerItems}>
                <Link to={"/pages/returns-refunds"}>Returns &amp; Refunds</Link>
              </li>
            </ul>
          </Col>
          <Col sm={12} md={3}>
            <div className={style.footerItemsHead}>
              <h3>CATEGORIES</h3>
            </div>
            <ul className={style.footerItemsMain}>
              {AllCategoryData?.map((data, index) => {
                return (
                  <li className={style.footerItems} key={index}>
                    <Link to={data?.cat_url}>{data?.medicine_category}</Link>
                  </li>
                );
              })}
            </ul>
          </Col>
          <Col sm={12} md={3}>
            <div className={style.footerItemsHead}>
              <h3>PAYMENT METHOD</h3>
            </div>
            <ul className={`${style.footerItemsMain} ${style.paymentMethod}`}>
              <li className={`${style.footerItems} ${style.paymentMethodImg}`}>
                <LazyLoadImage src="https://mchemist.com/lib/images/pay1.jpg" alt="" />
              </li>
              <li className={`${style.footerItems} ${style.paymentMethodImg}`}>
                <LazyLoadImage src="https://mchemist.com/lib/images/pay2.jpg" alt="" />
              </li>
              <li className={`${style.footerItems} ${style.paymentMethodImg}`}>
                <LazyLoadImage src="https://mchemist.com/lib/images/pay3.jpg" alt="" />
              </li>
              <li className={`${style.footerItems} ${style.paymentMethodImg}`}>
                <LazyLoadImage src="https://mchemist.com/lib/images/pay5.jpg" alt="" />
              </li>
              <li className={`${style.footerItems} ${style.paymentMethodImg}`}>
                <LazyLoadImage src="https://mchemist.com/lib/images/pay6.jpg" alt="" />
              </li>
            </ul>
          </Col>
          <Col sm={12} md={3}>
            <div className={style.footerItemsHead}>
              <h3>KEEP IN TOUCH</h3>
            </div>
            <ul className={`${style.footerItemsMain} ${style.keepInTouch}`}>
              <li className={style.footerItems}>
                <div
                  className={style.socialIcons}
                  onClick={() =>
                    window.open("https://www.facebook.com/mchemistindia")
                  }
                  title="Facebook"
                >
                  <Icon.Facebook size={24} />
                </div>
              </li>
              <li className={style.footerItems}>
                <div
                  className={style.socialIcons}
                  onClick={() =>
                    window.open("https://twitter.com/mChemistindia")
                  }
                  title="Twitter"
                >
                  <Icon.TwitterX size={24} />
                </div>
              </li>
            </ul>
          </Col>
        </Row>
        <div className={style.footerNotes}>
          <p>
            &gt; We do not sell medicines without a valid prescription. &gt; We
            do not dispense Schedule X, Schedule H1 Medicines, Antibiotics and
            Psychotropic Substances &gt;For sale in India only.
          </p>
        </div>
        <div className={style.footerCopyRight}>
          <p>Copyright © {date} mChemist All Rights Reserved.</p>
        </div>
      </Container>
      {isVisible && (
        <div className={style.scrollToTop} onClick={scrollToTop} title="Scroll to top">
          <LazyLoadImage src={uparrowIcon} alt="^" />
        </div>
      )}
      <div className={style.WhatsAppIcon} onClick={handleRedirectWhatsApp}>
        <LazyLoadImage src={whatsAppIcon} alt="whatsapp" />
      </div>
    </footer>
  );
}
