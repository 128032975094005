import React, { useState } from "react";
import { Container } from "react-bootstrap";
import style from "../../Assets/details.module.css";
import ButtonLoader from "../Common/ButtonLoader";
import Form from "react-bootstrap/Form";
import { getEnquiryDeleteProfileApi } from "../../services/Services";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const NeedHelp = () => {
  const [btnLoading, setBtnLoading] = useState(false);
  const [query, setQuery] = useState("");

  const LoginData = JSON.parse(localStorage.getItem("loginDetail"));
  const uid = LoginData?.data?.id;
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e?.preventDefault();
    try {
      const result = await getEnquiryDeleteProfileApi({
        user_id: uid,
        note: query,
      });
      if (result?.res?.status === 200) {
        toast.success(result?.res?.message);
        setTimeout(() => {
          navigate("/");
        }, 500);
        setQuery("");
      } else {
        toast.error(result?.res?.message);
      }
    } catch (error) {
      toast.error(error?.message);
    }
  };

  return (
    <Container>
      <div className={style.reviewSystemFixWidth}>
        <div className={style.reviewSystemMain}>
          <form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Control
                as="textarea"
                rows={5}
                value={query}
                onChange={(e) => setQuery(e?.target?.value)}
                className="customeInputField"
                placeholder="Drop your query here related to password change, profile issue, account deletion, and unsubscribe to newsletter etc."
              />
            </Form.Group>
            <div>
              <button
                type="submit"
                className="CommonButtonStyle"
                disabled={btnLoading}
              >
                {btnLoading && <ButtonLoader />}
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </Container>
  );
};

export default NeedHelp;
