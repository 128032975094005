import React, { useEffect, useState } from "react";
import styles from "../../Assets/trackorder.module.css";
import Container from "react-bootstrap/Container";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import * as Icon from "react-bootstrap-icons";
import { Link, useLocation } from "react-router-dom";
import OrderProgress from "./OrderProgress";
import { RatingIcon, ToggleIcon } from "../../utils/Icons";
import { getOrderTrackApi } from "../../services/Services";
import { toast } from "react-toastify";
import NoDataFound from "../Common/NoDataFound";
import Loader from "../Common/Loader";

const TrackOrder = () => {
  const [currentStage, setCurrentStage] = useState("Dispatched");
  const [showArriving, setShowArriving] = useState(false);
  const [trackData, setTrackData] = useState({});
  const [stagesData, setStagesData] = useState([]);
  const location = useLocation();
  const [loader, setLoader] = useState(false);
  const FetchTrackOrderData = async (data) => {
    try {
      setLoader(true);
      const result = await getOrderTrackApi(data);
      if (result?.res?.status === 200) {
        setLoader(false);
        setTrackData(result?.res?.data);
        const _CurrentStatus = Object.keys(result?.res?.data?.scans);
        const lastIndex = _CurrentStatus.length - 1;
        setCurrentStage(_CurrentStatus[lastIndex]);
        if (result?.res?.data?.shipping_status === "Delhivery") {
          setStagesData([
            "Pick up scheduled",
            "In Transit",
            "Dispatched",
            "Delivered",
          ]);
        } else {
          setStagesData([
            "Pick up scheduled",
            "In Transit",
            "Dispatched",
            "Delivered",
          ]);
        }
      }
      setLoader(false);
    } catch (error) {
      setLoader(false);
      toast.error(error?.message);
    }
  };

  useEffect(() => {
    if (location?.state?.id) {
      FetchTrackOrderData({
        order_id: location?.state?.id, 
      });
    }
  }, []);
  if (loader) {
    return <Loader />;
  }
  return (
    <div className={styles.trackOrderMain}>
      <Container>
        <Breadcrumb>
          <Breadcrumb.Item href="#">
            <Icon.House size={17} /> Home
          </Breadcrumb.Item>
          <Breadcrumb.Item href="/myorders">
            <Icon.PersonFill size={17} /> Orders
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Track Order</Breadcrumb.Item>
        </Breadcrumb>
        {Object.keys(trackData || {})?.length > 0 ? (
          <div className={styles.trackOrderSecMain}>
            <div className={styles.orderProgressMain}>
              <OrderProgress
                stagesData={stagesData}
                currentStage={currentStage}
              />
            </div>
            <div className={styles.trackingOrderIdSec}>
              <div className={styles.orderId}>
                <h3>Tracking order #{trackData?.order_id}</h3>
              </div>
              <div className={styles.ArrivingSectionAndBtns}>
                <div className={styles.ArrivingSection}>
                  <div
                    className={`${styles.arrivingToday} ${
                      showArriving ? styles.showArriving : ""
                    }`}
                    onClick={() => setShowArriving(!showArriving)}
                  >
                    {trackData?.expected_delivery_date ? (
                      <h3>
                        Arriving {trackData?.expected_delivery_date} by 10pm
                      </h3>
                    ) : (
                      <h3>Order Status:</h3>
                    )}
                    <span>{trackData?.current_status}</span>
                    {trackData?.current_status !== "Delivered" && (
                      <ToggleIcon />
                    )}
                  </div>
                  {showArriving &&
                    trackData?.current_status !== "Delivered" && (
                      <>
                        {trackData?.shipping_status === "Delhivery" && (
                          <div className={styles.ShowArrivingData}>
                            {trackData?.scans?.Delivered && (
                              <div className={styles.ShowArrivingStatus}>
                                <h3>{`${trackData?.scans?.Delivered?.scan} on ${trackData?.scans?.Delivered?.scan_time}`}</h3>
                                <p>
                                  {trackData?.scans?.Delivered?.scan_location}
                                </p>
                              </div>
                            )}
                            {trackData?.scans?.Dispatched && (
                              <div className={styles.ShowArrivingStatus}>
                                <h3>{`${trackData?.scans?.Dispatched?.scan} on ${trackData?.scans?.Dispatched?.scan_time}`}</h3>
                                <p>
                                  {trackData?.scans?.Dispatched?.scan_location}
                                </p>
                              </div>
                            )}
                            {trackData?.scans?.["In Transit"] && (
                              <div className={styles.ShowArrivingStatus}>
                                <h3>{`${trackData?.scans?.["In Transit"].scan} on ${trackData?.scans?.["In Transit"].scan_time}`}</h3>
                                <p>
                                  {
                                    trackData?.scans?.["In Transit"]
                                      ?.scan_location
                                  }
                                </p>
                              </div>
                            )}
                            {trackData?.scans?.["Pick up scheduled"] && (
                              <div className={styles.ShowArrivingStatus}>
                                <h3>{`${trackData?.scans?.["Pick up scheduled"].scan} on ${trackData?.scans?.["Pick up scheduled"].scan_time}`}</h3>
                                <p>
                                  {
                                    trackData?.scans?.["Pick up scheduled"]
                                      .scan_location
                                  }
                                </p>
                              </div>
                            )}
                          </div>
                        )}
                      </>
                    )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <NoDataFound />
        )}
      </Container>
    </div>
  );
};

export default TrackOrder;
