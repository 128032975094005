import React from 'react';
import styles from "../../Assets/trackorder.module.css";

const OrderProgress = ({ currentStage, stagesData }) => {
    const currentIndex = stagesData.indexOf(currentStage);
    const progressWidth = currentIndex / (stagesData.length - 1) * 100 + '%';

    return (
        <div className={styles.progressContainer}>
            <div className={styles.progressBar}>
                <div className={styles.progress} style={{ width: progressWidth }}></div>
            </div>
            {stagesData.map((stage, index) => {
                let stageClass = styles.stage;
                if (index === currentIndex) {
                    stageClass += ` ${styles.current}`;
                } else if (index < currentIndex) {
                    stageClass += ` ${styles.completed}`;
                } else {
                    stageClass += ` ${styles.pending}`;
                }
                return (
                    <div key={stage} className={stageClass}>
                        <div className={styles.stageIconoutline}>
                            <div className={styles.stageIcon}></div>
                        </div>
                        <div className={styles.stageName}>{stage}</div>
                    </div>
                );
            })}
        </div>
    );
};

export default OrderProgress;
