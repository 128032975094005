import React from "react";
import nodataImg from "../../image/nodata.png";
import { LazyLoadImage } from "react-lazy-load-image-component";

const NoDataFound = ({ noDataErrMsg }) => {
  return (
    <div className="noDatafoundMain">
      <div className="NoDataFoundcontent">
        <div className="noDataFoundImg">
          <LazyLoadImage src={nodataImg} alt="no data" />
        </div>
        <h3>No Data Found</h3>
        <p></p>
      </div>
    </div>
  );
};

export default NoDataFound;
