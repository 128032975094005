import React, { createContext, useContext, useState, useEffect } from "react";
import { toast } from "react-toastify";
import { formatNumberWithDecimal } from "../../utils/CommonFunction";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const CartContext = createContext();

export const useCart = () => {
  return useContext(CartContext);
};

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState(
    JSON.parse(localStorage.getItem("cartItems")) || []
  );

  const [recentSearches, setRecentSearches] = useState(
    JSON.parse(localStorage.getItem("recentSearches")) || []
  );
  const { isLoggedIn, setisLoggedIn } = useSelector((state) => state.auth);
  const location = useLocation();
  const MAX_RECENT_SEARCHES = 20;
  // new changes here for extra discount
  // useEffect(() => {
  //   const storedCart = JSON.parse(localStorage.getItem("cartItems")) || [];
  //   setCartItems(storedCart);
  //   const storedRecentSearches =
  //     JSON.parse(localStorage.getItem("recentSearches")) || [];
  //   setRecentSearches(storedRecentSearches);
  // }, []);
  let loginDetail = JSON.parse(localStorage.getItem("loginDetail"));
  // useEffect(() => {
  //   const storedCart = JSON.parse(localStorage.getItem("cartItems")) || [];
  //   if (loginDetail?.data?.extra_discount === 1) {
  //     const disCountData1 =
  //       loginDetail?.data?.discount_data && loginDetail?.data?.discount_data[0];
  //     const disCountData2 =
  //       loginDetail?.data?.discount_data && loginDetail?.data?.discount_data[1];
  //     const cartItemsWithDiscount = storedCart.map((item) => {
  //       let discountPercentage = Math.round(
  //         ((item.mrp - item.sale_rate) / item.mrp) * 100
  //       );
  //       let updatedSaleRate = formatNumberWithDecimal(item.sale_rate);
  //       if (item.presc_required === 1) {
  //         if (discountPercentage === Number(disCountData2?.discont_condition)) {
  //           discountPercentage = discountPercentage + Number(disCountData2?.discount_apply);
  //           updatedSaleRate = item.mrp - item.mrp * (discountPercentage / 100);
  //         }
  //       } else {
  //         if (discountPercentage === Number(disCountData1?.discont_condition)) {
  //           discountPercentage =
  //             discountPercentage + Number(disCountData1?.discount_apply);
  //           updatedSaleRate = item.mrp - item.mrp * (discountPercentage / 100);
  //         }
  //       }
  //       return {
  //         ...item,
  //         discountPercentage: discountPercentage,
  //         sale_rate: updatedSaleRate,
  //       };
  //     });
  //     setCartItems(cartItemsWithDiscount);
  //   } else {
  //     setCartItems(storedCart);
  //   }

  //   const storedRecentSearches =
  //     JSON.parse(localStorage.getItem("recentSearches")) || [];
  //   setRecentSearches(storedRecentSearches);
  // }, [isLoggedIn, location.pathname]);
  // end new changes


  useEffect(() => {
    const storedCart = JSON.parse(localStorage.getItem("cartItems")) || [];
    if (loginDetail?.data?.extra_discount === 1) {
        const discountData = loginDetail?.data?.discount_data || [];
        const cartItemsWithDiscount = storedCart.map((item) => {
            let discountPercentage = Math.round(
                ((item.mrp - item.sale_rate) / item.mrp) * 100
            );
            let updatedSaleRate = formatNumberWithDecimal(item.sale_rate);
            const applicableDiscount = discountData.find(discount => 
                discount.category === item.category
            );

            if (applicableDiscount) {
                if (item.presc_required === 1) {
                    if (discountPercentage === Number(applicableDiscount.discont_condition)) {
                        discountPercentage += Number(applicableDiscount.discount_apply);
                        updatedSaleRate = item.mrp - item.mrp * (discountPercentage / 100);
                    }
                } else {
                    if (discountPercentage === Number(applicableDiscount.discont_condition)) {
                        discountPercentage += Number(applicableDiscount.discount_apply);
                        updatedSaleRate = item.mrp - item.mrp * (discountPercentage / 100);
                    }
                }
            }

            return {
                ...item,
                discountPercentage: discountPercentage,
                sale_rate: updatedSaleRate,
            };
        });
        setCartItems(cartItemsWithDiscount);
    } else {
        setCartItems(storedCart);
    }

    const storedRecentSearches = JSON.parse(localStorage.getItem("recentSearches")) || [];
    setRecentSearches(storedRecentSearches);
}, [isLoggedIn, location.pathname]);
  useEffect(() => {
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
    localStorage.setItem("recentSearches", JSON.stringify(recentSearches));
  }, [cartItems, recentSearches]);

  const addToCart = (item) => {
    toast.success("Item added to cart!", {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
    });
    const existingItem = cartItems?.find(
      (cartItem) => cartItem?.unique_id === item?.unique_id
    );
    if (existingItem) {
      setCartItems(
        cartItems?.map((cartItem) =>
          cartItem?.unique_id === item?.unique_id
            ? { ...cartItem, quantity: Number(cartItem?.quantity) + 1 }
            : cartItem
        )
      );
    } else {
      setCartItems([
        ...cartItems,
        { ...item, quantity: item?.quantity ? Number(item?.quantity) : 1 },
      ]);
    }

    localStorage.removeItem("couponCode");
    localStorage.removeItem("couponDiscountAmount");
    localStorage.removeItem("couponApplied");
  };

  const removeFromCart = (itemId) => {
    setCartItems(
      cartItems?.filter((cartItem) => cartItem?.unique_id !== itemId)
    );
    localStorage.removeItem("couponCode");
    localStorage.removeItem("couponDiscountAmount");
    localStorage.removeItem("couponApplied");
  };

  const incrementQuantity = (itemId) => {
    setCartItems(
      cartItems?.map((cartItem) =>
        cartItem?.unique_id === itemId && cartItem?.quantity < 5
          ? { ...cartItem, quantity: cartItem?.quantity + 1 }
          : cartItem
      )
    );
  };

  const decrementQuantity = (itemId) => {
    setCartItems((prevCartItems) =>
      prevCartItems
        .map((cartItem) =>
          cartItem.unique_id === itemId
            ? cartItem.quantity > 1
              ? { ...cartItem, quantity: cartItem.quantity - 1 }
              : null
            : cartItem
        )
        .filter(Boolean)
    );
  };

  const addRecentSearch = (searchItem) => {
    const updatedRecentSearches = [
      searchItem,
      ...recentSearches.filter((item) => item.id !== searchItem.id),
    ].slice(0, MAX_RECENT_SEARCHES);
    setRecentSearches(updatedRecentSearches);
  };

  const clearCart = () => {
    setCartItems([]);
  };

  return (
    <CartContext.Provider
      value={{
        cartItems,
        addToCart,
        removeFromCart,
        incrementQuantity,
        decrementQuantity,
        clearCart,
        addRecentSearch,
        recentSearches,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
