import React, { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";
import Layout from "../components/Layout";
import Loader from "../components/Common/Loader";
import TrackOrder from "../components/Account/TrackOrder";
import ThankYouPrescription from "../components/Content/ThankYouPrescription";
import NeedHelp from "../components/NeedHelp";
import { useSelector } from "react-redux";
import { SigninWebApi } from "../services/Services";

const Home = lazy(() => import("../components/Home/Home"));
const MedicineSearch = lazy(() =>
  import("../components/Medicines/MedicineSearch")
);
const Brandshome = lazy(() =>
  import("../components/ShopByCategory/Brandshome")
);
const Categroyhome = lazy(() =>
  import("../components/ShopByCategory/Categoryhome")
);
const ShopByHealthConditionHome = lazy(() =>
  import("../components/ShopByCategory/ShopByHealthConditionHome")
);
const PopularProductHome = lazy(() =>
  import("../components/PopularProduct/PopularProductHome")
);
const Page = lazy(() => import("../components/Content/Page"));
const Prescription = lazy(() => import("../components/Cart/Prescription"));
const FailComponent = lazy(() => import("../components/Cart/FailComponent"));
const CartPage = lazy(() => import("../components/Cart/CartPage"));
const OrderSummaryPage = lazy(() =>
  import("../components/Cart/OrderSummaryPage")
);
const PaymentPage = lazy(() => import("../components/Cart/PaymentPage"));
const MedicineDetailsPage = lazy(() =>
  import("../components/Medicines/MedicineDetailsPage")
);
const ThankyouPage = lazy(() => import("../components/Content/ThankyouPage"));
const PatientMyAccountPage = lazy(() =>
  import("../components/Account/PatientMyAccountPage")
);
const PatientMedicineOrderPage = lazy(() =>
  import("../components/Account/PatientMedicineOrderPage")
);
const PatientMedicineOrderDetailPage = lazy(() =>
  import("../components/Account/PatientMedicineOrderDetailPage")
);
const PatientAddressListPage = lazy(() =>
  import("../components/Account/PatientAddressListPage")
);
const Mymedicine = lazy(() => import("../components/MyDrugs/Mydrug"));
const CreateReview = lazy(() => import("../components/Medicines/CreateReview"));
const PageNotFound = lazy(() => import("../components/Common/PageNotFound"));
const ValidateEmailLink = lazy(() => import("../components/Common/ValidateEmailLink"));

const RouterURL = () => {
  const { isLoggedIn } = useSelector((state) => state.auth);

  return (
    <Layout>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/categories/:ids" element={<MedicineSearch />} />
          <Route path="/brands/:ids" element={<MedicineSearch />} />
          <Route path="/detail/:ids" element={<MedicineDetailsPage />} />
          <Route path="brand" element={<Brandshome />} />
          <Route path="category" element={<Categroyhome />} />
          <Route
            path="healthcondition"
            element={<ShopByHealthConditionHome />}
          />
          <Route path="popularproduct" element={<PopularProductHome />} />
          <Route path="pages/:id" element={<Page />} />
          <Route path="basket" element={<CartPage />} />
          <Route path="basket/prescription" element={<Prescription />} />
          <Route path="basket/summaryPage" element={<OrderSummaryPage />} />
          <Route
            path="/basket/summaryPage/:id"
            element={<OrderSummaryPage />}
          />
          <Route path="payment" element={<PaymentPage />} />
          <Route path="/payment/all/:id" element={<PaymentPage />} />
          <Route path="thanks/:id" element={<ThankyouPage />} />
          <Route path="thank-you" element={<ThankYouPrescription />} />
          <Route path="failed" element={<FailComponent />} />
          <Route path="failed/:id" element={<FailComponent />} />
          <Route path="myaddress" element={<PatientAddressListPage />} />
          <Route path="myaccount" element={<PatientMyAccountPage />} />
          {isLoggedIn && (
            <Route path="/myaccount/help" element={<NeedHelp />} />
          )}
          {/* <Route    use this for protected routes
            path="myaccount"
            element={
              <ProtectedRoute>
                <PatientMyAccountPage />
              </ProtectedRoute>
            }
          /> */}
          <Route path="create-review/:id" element={<CreateReview />} />
          <Route path="myorders" element={<PatientMedicineOrderPage />} />
          <Route
            path="myorders/:id"
            element={<PatientMedicineOrderDetailPage />}
          />
          <Route path="myorders/trackorder/:id" element={<TrackOrder />} />
          <Route path="mymedicine" element={<Mymedicine />} />
          <Route path="validate-email" element={<ValidateEmailLink />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Suspense>
    </Layout>
  );
};

export default RouterURL;
