export const isExpired = (expiryDateString) => {
  const formattedExpiryDateString = expiryDateString.replace(
    /^(\d{2})-(\d{2})-(\d{4}) (\d{2}:\d{2} [APMapm]{2})$/,
    "$2-$1-$3 $4"
  );

  const expiryDate = new Date(formattedExpiryDateString);
  const currentDate = new Date();
  if (expiryDate > currentDate) {
    return false;
  } else {
    return true;
  }
};

export function formatDate(inputDate) {
  const dateParts = inputDate.split(" ");
  const dateStr = dateParts[0];
  const timeStr = dateParts[1] + " " + dateParts[2];

  const [day, month, year] = dateStr.split("-");

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const formattedDate = `${parseInt(day)} ${
    months[parseInt(month) - 1]
  }. ${parseInt(year)} ${timeStr}`;
  return formattedDate;
}

export const convertToSlug = (text) => {
  return text
    .toLowerCase()
    .replace(/[^\w\s-]/g, "")
    .replace(/\s+/g, "-")
    .replace(/--+/g, "-");
};

export const calculateAge = (birthdate) => {
  const birthdateDate = new Date(birthdate);
  const currentDate = new Date();
  let age = currentDate.getFullYear() - birthdateDate.getFullYear();
  const isBirthdayPassed =
    currentDate.getMonth() > birthdateDate.getMonth() ||
    (currentDate.getMonth() === birthdateDate.getMonth() &&
      currentDate.getDate() >= birthdateDate.getDate());
  if (!isBirthdayPassed) {
    age--;
  }
  return age;
};



// export const formatNumberWithDecimal =(value)=> {
//   const floatValue = parseFloat(value);
//   const formattedValue = Number.isInteger(floatValue) ? floatValue : floatValue.toFixed(1);
//   return formattedValue.toString();
// }


export const formatNumberWithDecimal = (value) => {
  const floatValue = parseFloat(value);
  const formattedValue = Number.isInteger(floatValue) ? floatValue : Math.round(floatValue * 10) / 10;
  return formattedValue.toString();
};

export const downloadFile = async (url) => {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Failed to fetch: ${response.status} ${response.statusText}`);
    }
    const blob = await response.blob();
    const objectURL = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = objectURL;
    link.download = "Invoice"; 
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    
  }
};
