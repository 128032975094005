import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import MegaMenu from "../MegaMenu/MegaMenu";
import { getPrimaryMenuApi } from "../../services/Services";

const Layout = ({ children }) => {
  const [AllCategoryData, setAllCategoryData] = useState([]);

    const fetchPrimaryMenuData = async () => {
      try {
        const result = await getPrimaryMenuApi();
        if (result?.res?.status === 200) {
          setAllCategoryData(result?.res?.data);
        }
      } catch (error) {
        console.log(error, "category list api")
      }
    };
  
    useEffect(() => {
      fetchPrimaryMenuData();
    }, []);

  return (
    <>
      <Header AllCategoryData ={AllCategoryData} />
      <MegaMenu />
      <main className="bodyLayoutMain">{children}</main>
      <Footer AllCategoryData ={AllCategoryData} />
    </>
  );
};

export default Layout;
