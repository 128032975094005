import React, { forwardRef } from "react";
import { Col, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Carousel from "react-bootstrap/Carousel";
import { BASE_URL, URL } from "../../url/url";
import Nav from "react-bootstrap/Nav";
import DatePicker from "react-datepicker";
import * as moment from "moment";
import ErrorCom from "./ErrorCom";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ButtonLoader from "./ButtonLoader";

const RegistrationModal = ({
  RegisterCompletePopupShow,
  setRegisterCompletePopupShow,
  getemail,
  CompleteRegisters,
  dob,
  handleLoginPopup,
  firstName,
  lastName,
  setLastName,
  setFirstName,
  error,
  setdob,
  btnLoading,
}) => {
  const health_Related_Queries = `${BASE_URL}/2023/05/login-signup/Health-Related-Queries.png`;
  const your_Go_To_Health_App = `${BASE_URL}/2023/05/login-signup/Your-Go-To-Health-App.png`;
  const know_Your_Medicines = `${BASE_URL}/2023/05/login-signup/Know-Your-Medicines.png`;
  const home_Delivery_of_Medicines = `${BASE_URL}/2023/05/login-signup/Home-Delivery-of-Medicines.png`;
  const formRef = React.useRef(null);

  const CustumInput = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-calendar-input form-control"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
      placeholder="Select DOB"
    />
  ));
  return (
    <Modal
      size="xl"
      className="loginModelBody"
      show={RegisterCompletePopupShow}
      onHide={() => setRegisterCompletePopupShow(false)}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Row>
          <Col sm={6}>
            <Carousel className="loginformSlider">
              <Carousel.Item>
                <LazyLoadImage
                  src={health_Related_Queries}
                  className="img-fluid"
                  alt=""
                />
                <Carousel.Caption>
                  <h3>Health Related Queries?</h3>
                  <p>
                    Consult our certified doctors from anywhere, anytime, and
                    for free. We guarantee your privacy.
                  </p>
                </Carousel.Caption>
              </Carousel.Item>

              <Carousel.Item>
                <LazyLoadImage
                  src={your_Go_To_Health_App}
                  className="img-fluid"
                  alt=""
                />
                <Carousel.Caption>
                  <h3>Make Healthcare Simpler</h3>
                  <p>
                    Get medicine information, order medicines, book lab tests
                    and consult doctors online from the comfort of your home.
                  </p>
                </Carousel.Caption>
              </Carousel.Item>

              <Carousel.Item>
                <LazyLoadImage
                  src={know_Your_Medicines}
                  className="img-fluid"
                  alt=""
                />
                <Carousel.Caption>
                  <h3>Know Your Medicines</h3>
                  <p>
                    View medicine information like usage, side effects and
                    cheaper substitutes before you take them.
                  </p>
                </Carousel.Caption>
              </Carousel.Item>

              <Carousel.Item>
                <LazyLoadImage
                  src={home_Delivery_of_Medicines}
                  className="img-fluid"
                  alt=""
                />
                <Carousel.Caption>
                  <h3>Medicines, Home Delivered</h3>
                  <p>
                    Order any medicine or health product and we’ll deliver it
                    for free. Enjoy discounts on everything.
                  </p>
                </Carousel.Caption>
              </Carousel.Item>

              <Carousel.Item>
                <LazyLoadImage
                  src={health_Related_Queries}
                  className="img-fluid"
                  alt=""
                />
                <Carousel.Caption>
                  <h3>Health Related Queries?</h3>
                  <p>
                    Consult our certified doctors from anywhere, anytime, and
                    for free. We guarantee your privacy.
                  </p>
                </Carousel.Caption>
              </Carousel.Item>
            </Carousel>
          </Col>

          <Col sm={6}>
            <div className="loginform">
              <Form validated={true} onSubmit={CompleteRegisters}>
                <h1>Registration</h1>
                <p>
                  Please provide basic information to complete registration.
                </p>
                <Form.Group className="mb-10" controlId="formBasicMobile">
                  <Form.Control
                    type="text"
                    maxLength={25}
                    onKeyPress={(event) => {
                      if (!/[A-Za-z_\s ]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    value={firstName}
                    required
                    onChange={(e) => setFirstName(e?.target?.value)}
                    placeholder="Enter First Name"
                  />
                  {error?.firstName && <ErrorCom error={error?.firstName} />}
                </Form.Group>
                <Form.Group className="mb-10 mt-20" controlId="formBasicMobile">
                  <Form.Control
                    type="text"
                    maxLength={25}
                    onKeyPress={(event) => {
                      if (!/[A-Za-z_\s ]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    required
                    value={lastName}
                    onChange={(e) => setLastName(e?.target?.value)}
                    placeholder="Enter Last Name"
                  />
                  {error?.lastName && <ErrorCom error={error?.lastName} />}
                </Form.Group>

                <Form.Group className="mb-10 mt-20" controlId="formBasicMobile">
                  <Form.Control
                    type="email"
                    maxLength={100}
                    variant="outline"
                    onChange={(e) => getemail(e)}
                    placeholder="Enter Email Address"
                    required
                  />
                  {error?.email && <ErrorCom error={error?.email} />}
                </Form.Group>

                <Form.Group className="mt-20 mb-10" controlId="formBasicMobile">
                  <DatePicker
                    selected={dob}
                    value={dob}
                    onChange={(date) => setdob(date)}
                    name="startDate"
                    dateFormat="dd/MM/yyyy"
                    isClearable={true}
                    maxDate={new Date()}
                    showYearDropdown
                    placeholderText="DOB"
                    wrapperClassName="widthFull"
                    yearDropdownItemNumber={100}
                    customInput={<CustumInput />}
                  />
                </Form.Group>
                {/* <input name="hiddenInput" value="hiddenValue" type="hidden" /> */}
                <button
                  //   onClick={(e) => CompleteRegisters(e)}
                  variant="primary"
                  type="submit"
                  className="CommonButtonStyle"
                  disabled={btnLoading}
                >
                  {btnLoading && <ButtonLoader />}
                  Submit
                </button>
                <p className="mt-10">
                  Have an account?{" "}
                  <Nav.Link className="LoginLink" onClick={handleLoginPopup}>
                    Login
                  </Nav.Link>
                </p>
                <p className="mt-20">
                  By signing up, you agree to our{" "}
                  <Link
                    className="LoginLink"
                    onClick={() => setRegisterCompletePopupShow(false)}
                    to="/pages/terms-and-conditions"
                  >
                    Terms and Conditions
                  </Link>
                  <span> & </span>
                  <Link
                    className="LoginLink"
                    to="/pages/privacy-policy"
                    onClick={() => setRegisterCompletePopupShow(false)}
                  >
                    Privacy Policy
                  </Link>
                </p>

                <p className="mt-20">
                  <Nav.Link className="LoginHelp" href="/pages/contact-us">
                    Need Help? Get In Touch
                  </Nav.Link>
                </p>
              </Form>
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default RegistrationModal;
