export const VERIFYOTP_SUCCESS = "VERIFYOTP_SUCCESS";
export const VERIFYOTP_FAIL = "VERIFYOTP_FAIL";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const COM_REGISTER_SUCCESS = "COM_REGISTER_SUCCESS";
export const COM_REGISTER_FAIL = "COM_REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const LOGINPRE_SUCCESS = "LOGINPRE_SUCCESS";
export const LOGINPRE_FAIL = "LOGINPRE_SUCCESS";
export const UPDATEUSER_SUCCESS = "UPDATEUSER_SUCCESS";
export const UPDATEUSER_FAIL = "UPDATEUSER_FAIL";
export const VERIFYOTP_LOGIN_SUCCESS = "VERIFYOTP_LOGIN_SUCCESS";
export const VERIFYOTP_LOGIN_FAIL = "VERIFYOTP_LOGIN_FAIL";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";



export const LISTADDRESS_SUCCESS = "LISTADDRESS_SUCCESS";
export const LISTADDRESS_FAIL = "LISTADDRESS_FAIL";

export const ADDADDRESS_SUCCESS = "ADDADDRESS_SUCCESS";
export const ADDADDRESS_FAIL = "ADDADDRESS_FAIL";

export const UPDATEADDRESS_SUCCESS = "UPDATEADDRESS_SUCCESS";

export const UPDATEADDRESS_FAIL = "UPDATEADDRESS_FAIL";
export const DELETEADDRESS_SUCCESS = "DELETEADDRESS_SUCCESS";
export const DELETEADDRESS_FAIL = "DELETEADDRESS_FAIL";

export const ADDFAMILY_SUCCESS = "LISTFAMILY_SUCCESS";
export const ADDFAMILY_FAIL = "LISTFAMILY_FAIL";
export const LISTFAMILY_SUCCESS = "LISTFAMILY_SUCCESS";
export const LISTFAMILY_FAIL = "LISTFAMILY_FAIL";
export const UPDATEFAMILY_SUCCESS = "UPDATEFAMILY_SUCCESS";

export const UPDATEFAMILY_FAIL = "UPDATEFAMILY_FAIL";
export const DELETEFAMILY_SUCCESS = "DELETEFAMILY_SUCCESS";
export const DELETEFAMILY_FAIL = "DELETEFAMILY_FAIL";