import React from "react";
import style from "../../Assets/cart.module.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import thanksIcon from "../../image/thanks.png";
import { useNavigate } from "react-router-dom";

const ThankYouPrescription = () => {
  const navigate = useNavigate();
  const handleRedirectWhatsApp = () => {
    window.open(
      `https://api.whatsapp.com/send/?phone=919625996302&text&type=phone_number&app_absent=0`
    );
  };
  return (
    <div className={style.thanksDivMAin}>
      <div className={`${style.thanksDiv} ${style.ThankYouPrescription}`}>
        <h2>Thanks for choosing <span>mCHEMIST</span> !</h2>
        <LazyLoadImage src={thanksIcon} alt="thanksIcon" />
        <p>
          Thank you for uploading your prescription. We are reviewing it. You
          will receive an update shortly. If you have any queries in the
          meantime, please contact us on WhatsApp at <span className={style.whatsappNum} onClick={handleRedirectWhatsApp}>+91-9625996302</span> or email at
          <a className={style.orderMail} href="mailto:order@mchemist.com"> order@mchemist.com</a> .
        </p>
        <div>
          <button
            className="CommonButtonStyle CommonButtonStyle2"
            onClick={() => navigate("/")}
          >
            Continue shopping
          </button>
        </div>
      </div>
    </div>
  );
};

export default ThankYouPrescription;
